import FormField from '@components/forms/fields/FormField'
import Link from '@components/common/Link'
import { Routes } from '@lib/constants'
import { buildLoginMutationFunction } from '@lib/user'
import { Formik, FormikErrors, FormikHelpers } from 'formik'
import { useRouter } from 'next/router'
import { useMutation } from 'react-query'
import * as Yup from 'yup'
import cx from 'classnames'
import Input from '@components/forms/fields/Input'
import Button from '@components/common/Button'
import { queryClient, UnprocessableEntity } from '@lib/fetcher'

type FormValues = {
  email: string
  password: string
}

const validationSchema = Yup.object().shape({
  email: Yup.string().required().email().label('E-Mail'),
  password: Yup.string().required().label('Passwort'),
})

const LoginForm: React.FC<{ buttonText?: string | null }> = ({ buttonText }) => {
  const router = useRouter()

  const loginMutation = useMutation(buildLoginMutationFunction())

  const handleSubmit = (values: FormValues, actions: FormikHelpers<FormValues>) => {
    loginMutation.mutate(values, {
      onSuccess: () => {
        queryClient.refetchQueries(['GetCurrentUser'])

        let redirect: string = Routes.Home

        if (router.query.redirect) {
          redirect = Array.isArray(router.query.redirect)
            ? router.query.redirect.join('/')
            : router.query.redirect
        }

        router.replace(redirect)
      },
      onError: (error) => {
        if (error instanceof UnprocessableEntity) {
          actions.setErrors(error.getSingleValidationErrors())
        }
      },
    })
  }

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{ email: '', password: '' }}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} className="space-y-9">
          <div className="space-y-3.5">
            <FormField label="E-Mail" name="email">
              <Input type="email" placeholder="E-Mail" />
            </FormField>

            <FormField label="Passwort" name="password">
              <Input type="password" placeholder="Passwort" />
            </FormField>

            <p>
              Passwort vergessen? <Link href="/passwort-vergessen">Hier</Link> zurücksetzen.
            </p>
          </div>

          <div className="">
            <Button type="submit" disabled={loginMutation.isLoading}>
              {buttonText ?? 'anmelden'}
            </Button>
          </div>
        </form>
      )}
    </Formik>
  )
}

export default LoginForm
