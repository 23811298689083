import Container from '@components/common/Container'
import LoginForm from '@components/forms/LoginForm'
import { fetchUser } from '@lib/user'
import type { NextPage } from 'next'
import { useRouter } from 'next/router'
import { dehydrate } from 'react-query'
import { Routes } from '@lib/constants'
import { createNewQueryClient, gqlFetcher } from '@lib/fetcher'
import {
  GetGlobalsDocument,
  GetGlobalsQuery,
  GetGlobalsQueryVariables,
  GetLoginGlobalDocument,
  GetLoginGlobalQuery,
  GetLoginGlobalQueryVariables,
  GlobalSet_LoginSectionLogin,
  useGetLoginGlobalQuery,
} from '@graphql/generated'
import { GetServerSidePageProps } from '@additional'
import ComponentLoader from '@components/component-loader/ComponentLoader'

type RequiredProps = {}

type OptionalProps = Partial<{}>

const Login: NextPage<RequiredProps & OptionalProps> = (props) => {
  const router = useRouter()

  const query = useGetLoginGlobalQuery(undefined, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })

  const data = query.data!.globalSet as GlobalSet_LoginSectionLogin

  return (
    <main className="flex min-h-[calc(100vh+theme(spacing[h-footer]))] flex-col items-center justify-start pt-h-header md:justify-center md:bg-gray-50">
      <div className="w-full pt-25 pb-[calc(var(--footer-height)+theme(spacing.10))] md:pt-0">
        <Container className="grid gap-20 md:grid-cols-2 md:gap-4">
          <div className="mx-auto w-full max-w-2xl space-y-5 bg-white px-4 md:px-12 md:pt-8 md:pb-12 lg:px-20 lg:pt-10 lg:pb-16 xl:px-35 xl:pt-14 xl:pb-25">
            <h1 className="font-serif text-5xl text-red-500">{data.login_title}</h1>

            <LoginForm buttonText={data.login_button_text} />
          </div>

          <div className="mx-auto w-full max-w-2xl space-y-5 bg-white px-4 md:px-12 md:pt-8 md:pb-12 lg:px-20 lg:pt-10 lg:pb-16 xl:px-35 xl:pt-14 xl:pb-25">
            <h2 className="font-serif text-5xl text-red-500">{data.register_title}</h2>

            <ComponentLoader group="bard" components={data.register_text ?? []} />
          </div>
        </Container>
      </div>
    </main>
  )
}

export const getServerSideProps: GetServerSidePageProps = async (context) => {
  const queryClient = createNewQueryClient()

  const [user] = await Promise.all([
    fetchUser(context.req).catch(() => false),
    queryClient.prefetchQuery(
      ['GetLoginGlobal'],
      gqlFetcher<GetLoginGlobalQuery, GetLoginGlobalQueryVariables>(
        GetLoginGlobalDocument,
        {},
        {},
        undefined,
        context.previewData?.previewToken
      )
    ),
  ])

  if (user) {
    return {
      redirect: {
        permanent: false,
        destination: Routes.Home,
      },
    }
  }

  return {
    props: {
      dehydratedState: dehydrate(queryClient),

      meta: {
        hidden: true,
      },
    },
  }
}

export default Login
